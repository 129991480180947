import { PRIORITY_ORDER } from "./constants";
import { SourceStatus } from "./interfaces";

const getOrder = (article) => PRIORITY_ORDER[article.priority] || -1;

const articleComparator = (a1, a2) => {
  return (
    getOrder(a2) - getOrder(a1) ||
    a2.originalTime.localeCompare(a1.originalTime)
  );
};

const getHeaders = (user) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${user.signature}`,
});

export const fetchSourceGroups = async ({ status = SourceStatus.Created }) => {
  const { sourceGroups = [] } = await fetch(
    `/api/sources?status=${status}`
  ).then((data) => data.json());

  return sourceGroups.sort(articleComparator);
};

export const postCreateSourceGroup = async ({
  comments,
  sourceUrl,
  priority,
}) => {
  return await fetch("/api/sources", {
    method: "POST",
    body: JSON.stringify({
      comments,
      sourceUrl,
      priority,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postUpdateSourceGroup = async (user, { id, data }) => {
  await fetch(`/api/sources/${id}`, {
    method: "POST",
    body: JSON.stringify({
      sourceGroup: {
        ...data,
      },
    }),
    headers: getHeaders(user),
  });
};

export const fetchUsers = async (user) => {
  const { users } = await fetch(`/api/users`, {
    method: "GET",
    headers: getHeaders(user),
  }).then((data) => data.json());
  return users;
};

export const fetchDescubridor = async () => {
  return await fetch(`/api/descubridor`, {
    method: "GET",
  }).then((data) => data.json());
};

export const runDescubridor = async () => {
  return await fetch("/api/descubridor", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postDeleteSourceFromGroup = async ({
  sourceId,
  sourceGroupId,
}) => {
  return await fetch(
    `/api/sources/${sourceGroupId}/deleteSourceFromGroup?sourceId=${sourceId}`
  ).then((data) => data.json());
};

export const postAddSourceToGroup = async ({ sourceGroupId, url }) => {
  return await fetch(
    `/api/sources/${sourceGroupId}/addSourceToGroup?url=${url}`
  ).then((data) => data.json());
};

export const postDeleteSourceGroup = async ({ sourceGroupId }) => {
  await fetch(`/api/sources/${sourceGroupId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postDeleteSourceGroups = async (user, ids: string[]) => {
  await fetch(`/api/sources?ids=${ids.join(",")}`, {
    method: "DELETE",
    headers: getHeaders(user),
  });
};

export const postModifyDescubridor = async ({
  options = null,
  pending = null,
}) => {
  await fetch(`/api/descubridor`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      options,
      pending,
    }),
  });
};

export const postCombineSourceGroups = async (user, ids) => {
  await fetch(`/api/actions/combineSourceGroups?ids=${ids.join(",")}`, {
    method: "POST",
    body: JSON.stringify({
      ids,
    }),
    headers: getHeaders(user),
  });
};
