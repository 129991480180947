import { useRef, useEffect, useState, useMemo } from "react";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const usePromise = (
  callback: () => Promise<any>,
  deps = [],
  {
    initialIsLoading = true,
    initialProps = null,
    then = undefined,
    map = (data) => data,
    resetValue = true,
  } = {}
) => {
  const [isLoading, setLoading] = useState(initialIsLoading);
  const [props, setProps] = useState(initialProps);

  let isValid = true;

  useEffect(() => {
    setLoading(true);
    resetValue && setProps(initialProps);
    Promise.resolve(callback())
      .then((rawData) => {
        if (!isValid) return;

        const data = map(rawData);
        setProps(data);
        then?.(data, rawData);
        setLoading(false);
      })
      .catch((error) => {
        if (!isValid) {
          return console.error({
            fn: "usePromise",
            error,
          });
        }
        throw error;
      });

    return () => (isValid = false);
  }, deps);

  return useMemo(
    () => ({
      isLoading,
      props,
    }),
    [isLoading, props]
  );
};

export const useIsFirstRenderRef = () => {
  const isFirstRenderRef = useRef(true);
  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);
  return isFirstRenderRef;
};

export const useIsFirstRender = () => {
  return useIsFirstRenderRef().current;
};
