import { useRouter } from "next/router";

import createPersistedState from "use-persisted-state";
const usePersistedUser = createPersistedState("themedizine:user");

import { useEffect, useState, createContext, useContext, useMemo } from "react";

const AuthContext = createContext({} as any);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ renderChildren }) => {
  const router = useRouter();
  const { auth } = router.query;

  const [user, setUser] = usePersistedUser(null);
  const [isLoading, setLoading] = useState(!!auth);

  useEffect(() => {
    setLoading(false);

    if (!auth || typeof auth !== "string") {
      return;
    }

    fetch(`/api/auth?auth=${auth}`)
      .then((data) => data.json())
      .then(({ user }) => {
        setUser(user || null);
        setLoading(false);
        router.push("/");
      });
  }, [auth]);

  const logout = () => setUser(null);

  const value = useMemo(
    () => ({
      user,
      isLoading,
      logout,
    }),
    [user, isLoading]
  );

  if (isLoading) {
    return <h1>CONECTANDOOO</h1>;
  }

  if (!user) {
    return (
      <a href="https://cms.themedizine.com/wp-admin/admin.php?page=editorial">
        Login
      </a>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {renderChildren()}
    </AuthContext.Provider>
  );
};
