import { usePromise, useInterval } from "./hooks";
import { useState, createContext, useMemo, useContext } from "react";
import { SourceGroup, SourceStatus } from "./interfaces";
import { fetchSourceGroups, fetchUsers } from "./fetchers";
import { useAuth } from "./AuthContext";

const DataContext = createContext<{
  refresh: () => void;
  sourceGroups: SourceGroup[];
  users: any[];
  isLoading: boolean;
  status: SourceStatus;
  setStatus: (status: SourceStatus) => void;
}>({} as any);

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const { user } = useAuth();
  const [status, setStatus] = useState(SourceStatus.Created);
  const [refreshKey, setRefreshKey] = useState(true);
  const refresh = () => setRefreshKey(!refreshKey);

  const { props: sourceGroups, isLoading: isLoadingSourceGroups } = usePromise(
    () => fetchSourceGroups({ status }),

    [refreshKey, status],
    {
      resetValue: false,
    }
  );

  const { props: users, isLoading: isLoadingUsers } = usePromise(
    () => fetchUsers(user),
    []
  );

  // Refresh each minute
  useInterval(() => {
    console.log("Scheduled refresh");
    refresh();
  }, 60 * 1000);

  const value = useMemo(
    () => ({
      refresh,
      sourceGroups,
      users,
      isLoading: isLoadingUsers || isLoadingSourceGroups,
      status,
      setStatus,
    }),
    [
      refresh,
      sourceGroups,
      isLoadingSourceGroups,
      users,
      isLoadingUsers,
      status,
    ]
  );

  return <DataContext.Provider value={value}>{children} </DataContext.Provider>;
};
