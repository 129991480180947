export enum Priority {
  High = "HIGH",
  Mid = "MID",
  Low = "LOW",
}

export enum SourceStatus {
  Created = "CREATED",
  Assigned = "ASSIGNED",
  Done = "DONE",
  Deleted = "DELETED",
  Merged = "MERGED",
}

export interface Source {
  url: string;
  title?: string;
  description?: string;
  siteName?: string;
  image?: string;
  publishedTime?: string;
}

export interface SourceGroup {
  id: string;
  sources: Source[];
  comments?: string;
  priority?: Priority;
  status: SourceStatus;
}
