import { SourceStatus } from "./interfaces";

// Highest first
export const PRIORITY = Object.freeze({
  URGENT: "URGENT",
  HIGH: "HIGH",
  MID: "MID",
  LOW: "LOW",
});
export const PRIORITY_ORDER = Object.keys(PRIORITY)
  .reverse()
  .reduce((acc, priority, index) => ({ ...acc, [priority]: index + 1 }), {});

export const PRIORITY_COLOR = Object.freeze({
  [PRIORITY.URGENT]: "#d9534f",
  [PRIORITY.HIGH]: "#f0ad4e",
  [PRIORITY.MID]: "#ffe500",
  [PRIORITY.LOW]: "#5cb85c",
});

export const PRIORITY_COLOR_DEFAULT = "#777";

export const PRIORITY_NAME = Object.freeze({
  [PRIORITY.URGENT]: "Urgente",
  [PRIORITY.HIGH]: "Alta",
  [PRIORITY.MID]: "Media",
  [PRIORITY.LOW]: "Baja",
});

export const FINAL_SOURCE_STATUS = new Set([
  SourceStatus.Deleted,
  SourceStatus.Done,
]);
